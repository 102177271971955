import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const dailyReportAdaptor = createEntityAdapter({
    selectId: (entity) => entity.id,
})
const initialDailyReport = dailyReportAdaptor.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDailyReportDate: builder.query({
            query: ({ page }) => `/b2g/eco-admin/segregation?page=${page}&limit=12`,
            transformResponse: responseData => {
                const { total_pages, data } = responseData?.data
                return { total_pages, entities: data };
            },

            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (result?.entities) {
                    return [
                        { type: 'DailyReportDate', id: 'List' },
                        ...result.entities.map((iec) => ({ type: 'DailyReportDate', id: iec.id })),
                    ];
                }
            }
        }),
        getDailyReport: builder.query({
            query: ({ page, searchDailyReport, date }) => {

                let queryString = `b2g/eco-admin/segregation/${date}?page_number=${page}`;

                if (!searchDailyReport.start_date && !searchDailyReport.end_date && !searchDailyReport.prp_name && !searchDailyReport.is_segregate && !searchDailyReport.is_collected) {
                    // No query parameters added since all are empty
                }
                else {
                    // Add start_date, end_date, prp_name, is_segregate, and is_collected if they are provided
                    if (searchDailyReport.start_date) {
                        queryString += `&start_date=${searchDailyReport.start_date}`;
                    }

                    if (searchDailyReport.end_date) {
                        queryString += `&end_date=${searchDailyReport.end_date}`;
                    }

                    if (searchDailyReport.prp_name) {
                        queryString += `&prp_name=${searchDailyReport.prp_name}`;
                    }

                    if (searchDailyReport.is_segregate !== undefined) {
                        queryString += `&is_segregate=${searchDailyReport.is_segregate}`;
                    }

                    if (searchDailyReport.is_collected !== undefined) {
                        queryString += `&is_collected=${searchDailyReport.is_collected}`;
                    }
                }
                return queryString;
            },
            transformResponse: responseData => {
                const { totalProperties, totalPage, data } = responseData?.data
                return { totalProperties, totalPage, entities: data };
            },

            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (result?.entities) {
                    return [
                        { type: 'DailyReport', id: 'List' },
                        ...result.entities.map((iec) => ({ type: 'DailyReport', id: iec.id })),
                    ];
                }
            }
        }),

    })
})

export const {
    useGetDailyReportQuery,
    useGetDailyReportDateQuery
} = extendedApiSlice

// 1998