import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
const wardsAdaptor = createEntityAdapter({
    selectId: (entity) => entity.id,
})
export const communitiesAdaptor = createEntityAdapter({
    selectId: (entity) => entity.community_id,
})

const eventManagerAdaptor = createEntityAdapter({
    selectId: (entity) => entity.name,
})

const initialWards = wardsAdaptor.getInitialState()
const initialCommunities = communitiesAdaptor.getInitialState()
const initialEventManager = eventManagerAdaptor.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEvent: builder.query({
            query: () => '/b2g/eco-admin/event',
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return wardsAdaptor.setAll(initialWards, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [
                        { type: 'Event', id: "List" },
                        ...result.ids.map(id => ({ type: 'Event', id }))
                    ]
                }
            }
        }),
        addNewEvent: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/event',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'Event', id: 'List' }
            ]
        }),
        editEvent: builder.mutation({
            query: payLoad => ({
                url: `/b2g/eco-admin/event`,
                method: 'PATCH',
                body: payLoad.body,
            }),
            invalidatesTags: [
                { type: 'Event', id: 'List' }
            ]
        }),
        deleteEvent: builder.mutation({
            query: id => ({
                url: `/b2g/eco-admin/event`,
                method: 'DELETE',
                body: id
            }),
            invalidatesTags: [
                { type: 'Event', id: 'List' }
            ]
        }),
        getEventmanager: builder.query({
            query: (propertyId) => `/b2g/eco-admin/assign-manager-to-event?event_id=${propertyId}`,
            transformResponse: responseData => {

                return { entities: responseData?.data };
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.entities) {
                    return [
                        { type: 'Event', id: "List" },
                        ...result.entities.map(id => ({ type: 'Event_manager', id }))
                    ]
                }
            }
        }),
        addNewEventmanager: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/assign-manager-to-event',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'Event', id: 'List' }
            ]
        }),
        getEventManagerList: builder.query({
            query: (page) => `/b2g/eco-admin/event-manager`,
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return eventManagerAdaptor.setAll(initialEventManager, responseData?.data || [])
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (result?.ids) {
                    return [
                        { type: 'Event', id: 'List' },
                        ...result.ids.map((EventManager) => ({ type: 'EventManager', id: EventManager.id })),
                    ];
                }
            }
        }),
        managerEventListDelete: builder.mutation({
            query: (id) => ({
                url: `/b2g/eco-admin/assign-manager-to-event/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'Event', id: 'List' }
            ]
        }),
    })
})

export const {
    useGetEventQuery,
    useAddNewEventMutation,
    useEditEventMutation,
    useDeleteEventMutation,
    useGetEventmanagerQuery,
    useAddNewEventmanagerMutation,
    useGetEventManagerListQuery,
    useManagerEventListDeleteMutation,
} = extendedApiSlice

