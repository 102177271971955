import React, { useState, useRef } from "react";
import swal from "sweetalert";
import { useAddNewEventMutation } from "./EventSlice";

const AddEvent = () => {

    const [addNewEvent, { isLoading }] = useAddNewEventMutation()
    const [event, setEvent] = useState({ ename: '' })
    const refClose = useRef('');

    const changeHandle = (e) => {
        const { name, value } = e.target
        setEvent({ ...event, [name]: value })
    }

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            await addNewEvent({ name: event.ename }).unwrap()
            setEvent({ ename: '', enumber: '', epassword: '' })
            swal("Success", "Data Added Successfully")
            refClose.current.click()
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <button type="button" className="bg-left-navabr  text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas" aria-controls="depOffCanvas"><i className="fa fa-plus" aria-hidden="true"></i> Add New Waste</button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffCanvas" aria-labelledby="depOffCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Waste</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Name
                                </label>
                                <input value={event.ename} onChange={changeHandle} className="form-control" type='text' name='ename' />

                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="bg-left-navabr  text-light  btn btn-secondary w-100 mt-3 rounded-pill"
                                    onClick={handleClick}
                                >
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEvent;