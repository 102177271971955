import React, { useState, useRef } from "react";
import swal from "sweetalert";
import { useAddNewEventmanagerMutation, useGetEventManagerListQuery } from "./EventSlice";

const AddEventmanager = (propertyId) => {
    const [addNewEventmanager, { isLoading }] = useAddNewEventmanagerMutation()
    const [event, setEvent] = useState({ ename: '' })
    const [Data, setData] = useState('')
    const refClose = useRef('');

    const {
        data: EventManagerList
    } = useGetEventManagerListQuery('getEvent')
    console.log("EventManagerList", EventManagerList?.ids);
    const { EventManagerSelect } = useGetEventManagerListQuery('getEventManager', {
        selectFromResult: ({ data }) => ({
            EventManagerSelect: data?.entities[Data],
        }),
    });

    
    const handleClick = async (e) => {
        e.preventDefault();
        try {
            await addNewEventmanager({
                "event_id": propertyId.propertyId,
                "manager_id": EventManagerSelect.id
            }).unwrap()
            setEvent({ ename: '', enumber: '', epassword: '' })
            swal("Success", "Data Added Successfully")
            refClose.current.click()
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <button type="button" className="bg-left-navabr  text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas" aria-controls="depOffCanvas"><i className="fa fa-plus" aria-hidden="true"></i> Add New Event Manager</button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffCanvas" aria-labelledby="depOffCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Event Manager</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Name
                                </label>
                                <select className="form-select" id="state_id" name="state_id" onChange={(e) => setData(e.target.value)}>
                                    <option value="" defaultValue>
                                        Select States
                                    </option>
                                    {EventManagerList?.ids?.map((index) => {
                                        return <>
                                            <option value={index}>{index}</option>
                                        </>
                                    })}
                                </select >
                            </div>

                            <div className="col-md-12">
                                <button button type="submit" className="bg-left-navabr  text-light  btn btn-secondary w-100 mt-3 rounded-pill"
                                    onClick={handleClick}
                                // disabled={!(true)}
                                >
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div >
            </div >
        </>
    )
}

export default AddEventmanager;