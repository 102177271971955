import React, { useRef, useState } from 'react'
import { useEditEventManagerMutation } from './EventManagerSlice';
import swal from 'sweetalert';

const EditEventManager = ({ refOpen, editEventManagers, setEditEventManagers }) => {

    const [buttonDisbale, setButtonDisabled] = useState(false);
    const [editEventManagerData, { isLoading }] = useEditEventManagerMutation()
    const refClose = useRef(null);

    const handleClick = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        try {
            await editEventManagerData({ body: { name: editEventManagers.name, number: editEventManagers.number, id: editEventManagers.id } }).unwrap();
            refClose.current.click();
            setEditEventManagers({ name: '', number: '', id: '' })
            swal('Success', "Data Edited Successfully...")
        } catch (e) {
            console.error("Error in Daily Waste edit ->", e);
        } finally {
            setButtonDisabled(false);
        }
    };

    return (
        <>
            <button ref={refOpen} type="button" className="btn d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas1" aria-controls="depOffCanvas1"></button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="depOffCanvas1" aria-labelledby="depOffCanvas1">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Edit Daily Waste</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Name
                                </label>
                                <input
                                    value={editEventManagers.name}
                                    onChange={(e) => { setEditEventManagers({ ...editEventManagers, [e.target.name]: e.target.value }) }}
                                    className="form-control"
                                    type='text'
                                    name='name'
                                />
                            </div>

                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Number
                                </label>
                                <input
                                    value={editEventManagers.number}
                                    onChange={(e) => { setEditEventManagers({ ...editEventManagers, [e.target.name]: e.target.value }) }}
                                    className="form-control"
                                    type='tel'
                                    name='number'
                                />
                            </div>

                            <div className="col-md-12">
                                <button type="submit" disabled={buttonDisbale} className="btn btn-secondary w-100 btn-block rounded-pill" onClick={handleClick}>
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditEventManager