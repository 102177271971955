import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const supervisorAdaptor = createEntityAdapter({
    selectId: (entity) => entity.supervisor_id,
})

const initialSupervisor = supervisorAdaptor.getInitialState()
const WardAdaptor = createEntityAdapter({
    selectId: (entity) => entity.ward_id,
})

const initialWardAdaptor = supervisorAdaptor.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSupervisor: builder.query({
            query: () => '/b2g/eco-admin/supervisor',
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return supervisorAdaptor.setAll(initialSupervisor, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error || !result?.ids) {
                    return [{ type: 'B2gSupervisor', id: 'List' }, []];
                } else {
                    return result.ids.map(id => ({ type: 'B2gSupervisor', id }));
                }
            }
        }),
        addNewSupervisor: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/supervisor',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'B2gSupervisor', id: 'List' }
            ]
        }),
        getWardlist: builder.query({
            query: () => '/b2g/eco-admin/address/community/ward',
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return WardAdaptor.setAll(initialWardAdaptor, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error || !result?.ids) {
                    return [{ type: 'B2gSupervisor', id: 'List' }, []];
                } else {
                    return result.ids.map(id => ({ type: 'B2gSupervisor', id }));
                }
            }
        })
    })
})

export const {
    useGetSupervisorQuery,
    useAddNewSupervisorMutation,
    useGetWardlistQuery,
} = extendedApiSlice