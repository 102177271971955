import B2GBreadcrumb from "../../components/breadcrumb/B2GBreadcrumb";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
import { useEffect, useState } from "react";
import { useGetDailyReportDateQuery, useGetDailyReportQuery } from "./DailyReportSlice";
import { useNavigate } from "react-router-dom";
import Pagination from "../../utils/Pagination";
import moment from "moment";

const DailyReportDate = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(() => {
        const savedPage = localStorage.getItem('dailyReportPage');
        return savedPage ? Number(savedPage) : 1;
    })
    useEffect(() => {
        localStorage.setItem('dailyReportPage', page);
    }, [page]);

    const {
        data: dailyReportDateList,
        isLoading,
    } = useGetDailyReportDateQuery({ page })


    let totalPages = 1;

    if (dailyReportDateList && dailyReportDateList.entities) {
        totalPages = dailyReportDateList.total_pages;
    }



    return (
        <>
            {isLoading && <SpinnerForPage />}
            <div className="container-fluid mt-5">
                <div className="card card-dark">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr className="text-left search_button">
                                        <th scope="col">Date</th>
                                        <th scope="col">Total Reports</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {dailyReportDateList?.entities?.map((e, index) => {
                                        return (
                                            <>
                                                <tr key={index} className="search_button listData text-left fw-normal" onClick={() => { navigate(`/b2g/dailyreportDate`, { state: e.dates }) }}>
                                                    <td>{moment(e.dates).format("YYYY-MM-DD").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</td>
                                                    <td>{e.total_marking}</td>
                                                </tr>
                                                <div key={index} className="Card_table_data" onClick={() => { navigate(`/b2g/dailyreportDate`, { state: e.dates }) }}>
                                                    <div div className="Card_data">
                                                        <span className="Card_srNo">{index + 1}</span>
                                                    </div>
                                                    <div className="Card">
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Date :-</span>
                                                            <span className="Card_data_list">{moment(e.dates).format("YYYY-MM-DD").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</span>
                                                        </div>
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Total Reports :-</span>
                                                            <span className="Card_data_list">{e.total_marking}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Pagination page={page} totalPages={totalPages} setPage={setPage} />
            </div>

        </>
    )
}

export default DailyReportDate;