import { useGetDriversQuery } from "./driverSlice";

const DriverExcerpt = ({ driverId, index }) => {
    const { driver } = useGetDriversQuery('getDrivers', {
        selectFromResult: ({ data }) => ({
            driver: data?.entities[driverId]
        }),
    })

    return (
        <>
            <tr key={index} className="search_button text-left fw-normal">
                <td>{index + 1}</td>
                <td>{driver?.p_name}</td>
                <td>{driver?.p_number}</td>
                <td>{driver?.p_email !== (null || '' || undefined) ? 'N/A' : (driver?.p_email)}</td>
                <td><p style={{ height: "15px", width: "15px" }} className={`rounded-circle ${driver?.is_leave === 0 ? 'bg-success' : 'bg-danger'}  `}> </p> </td>
            </tr>
            <div key={index} className="Card_table_data">
                <div div className="Card_data">
                    <span className="Card_srNo">{index + 1}</span>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Name :-</span>
                        <span className="Card_data_list">{driver?.p_name}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Number:-</span>
                        <span className="Card_data_list">{driver?.p_number}</span>
                    </div>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Email :-</span>
                        <span className="Card_data_list">{driver?.p_email !== (null || '' || undefined) ? 'N/A' : (driver?.p_email)}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Leave :-</span>
                        <span className="Card_data_list"><p style={{ height: "15px", width: "15px" }} className={`rounded-circle ${driver?.is_leave === 0 ? 'bg-success' : 'bg-danger'}  `}> </p></span>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DriverExcerpt