
import { useNavigate } from "react-router-dom";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
import B2GBreadcrumb from "../../components/breadcrumb/B2GBreadcrumb";
import { useRef, useState } from "react";
import { useGetEventQuery } from "./EventSlice";
import EventExcerpt from "./EventExcerpt";
import AddEvent from "./AddEvent";
import EditEvent from "./EditEvent";

const EventList = () => {

    // const refOpen = useRef(null)
    const eventRefOpen = useRef(null)
    const [editEvents, setEditEvents] = useState({ name: "", number: "" });

    const {
        data: Event,
        isLoading,
        isSuccess,
        isError,
    } = useGetEventQuery('getEvent')

    const editEvent = (Event) => {
        eventRefOpen.current.click()
        console.log(Event, "event");

        setEditEvents({ name: Event.name, event_id: Event.id });
    }

    return (
        <>
            {isSuccess && <div className="container-fluid mt-5">
                <div className="container-fluid mt-5">
                    <div className="w-100 d-flex justify-content-end gap-5">
                        <div className="col-md-3 text-right">
                            <AddEvent />
                        </div>
                    </div>

                    <div className="card card-dark mt-4">
                        <div className="table-border-style">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead className="search_button">
                                        <tr className=" text-left">
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Active</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Event?.ids.map((e, id) => {
                                                return <EventExcerpt EventId={e} index={e} editEvent={editEvent} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {isError && <div>Something went wrong</div>}

            <EditEvent eventRefOpen={eventRefOpen} editEvents={editEvents} setEditEvents={setEditEvents} />
        </>
    )
}

export default EventList