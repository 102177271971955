import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import MainBreadcrumb from "../../components/breadcrumb/MainBreadcrumb";
import DailyWasteExcerpt from "./DailyWasteExcerpt";
import AddDailyWaste from "./AddDailyWaste";
import EditDailyWaste from "./EditDailyWaste";
import { useGetDailyWasteQuery } from "./dailyWasteSlice";
import { useGetWasteCategoryQuery } from "../Categories/Category/categorySlice";
import { useGetSubWasteCategoryQuery } from "../Categories/SubCategory/subCategorySlice";
import Pagination from "../../utils/Pagination";

const DailyWaste = () => {

    const refOpen = useRef(null)
    const [stateEditDailyWaste, setStateEditDailyWaste] = useState()
    const [wasteId, setWasteId] = useState()
    const [page, setPage] = useState(() => {
        const savedPage = sessionStorage.getItem('dailyWastePage');
        return savedPage ? Number(savedPage) : 1;
    })

    useEffect(() => {
        sessionStorage.setItem('dailyWastePage', page);
    }, [page]);
    const navigate = useNavigate();

    const {
        data: dailyWaste,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDailyWasteQuery(page)


    let totalPages = 0;

    if (dailyWaste && dailyWaste.entities) {
        totalPages = dailyWaste.totalPage;
    }

    const {
        data: subCategory,
    } = useGetSubWasteCategoryQuery('getSubWasteCategory')

    const filter = dailyWaste?.ids?.map((e, i) => {
        const data = dailyWaste?.entities[e].w_waste || '[]'
        let xyj = JSON.parse(data) || [];
        return xyj.map((e, i) => e[0])
    })


    const [status, setStatus] = useState("");

    const handleReset = () => window.location.reload();

    const editDailyWaste = (index) => {
        refOpen.current.click()
        setStateEditDailyWaste({

            waste: Object.assign({}, ...JSON.parse(dailyWaste?.entities[index]?.w_waste).map((e) => ({ [e[0]]: e[1] }))),
            collection_date: dailyWaste?.entities[index]?.w_collection_date
        })
        setWasteId(dailyWaste?.entities[index]?.w_id)

    }

    return (
        <>
            {isError && <div>Something went wrong</div>}
            {dailyWaste &&
                <div className="container-fluid mt-5" >
                    <div className="d-flex justify-content-end">
                        <div className="text-right ">
                            <AddDailyWaste subCategory={subCategory} />
                        </div>
                    </div>

                    <div className="card card-dark mt-4" style={{ height: "50%" }}>
                        {

                            (
                                <div className="table-border-style">
                                    <div className="table-responsive sidebarhome ">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr className="text-center">
                                                    <th scope="col">Sr. No.</th>
                                                    <th scope="col">Collection Date</th>
                                                    <th scope="col">Waste</th>
                                                    <th scope="col">Total Waste</th>
                                                    <th scope="col">Creation Date</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dailyWaste?.entities?.map((dailyWaste, index) => {
                                                        return (
                                                            <DailyWasteExcerpt key={index} page={page} dailyWaste={dailyWaste} index={index} subCategory={subCategory} editDailyWaste={editDailyWaste} />
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            }

            <Pagination page={page} totalPages={totalPages} setPage={setPage} />

            <EditDailyWaste subCategory={subCategory} refOpen={refOpen} stateEditDailyWaste={stateEditDailyWaste} setStateEditDailyWaste={setStateEditDailyWaste} wasteId={wasteId} />

        </>
    )
}

export default DailyWaste;