import React, { useState, useRef } from "react";
import { useAddNewEventManagerMutation } from "./EventManagerSlice";
import swal from "sweetalert";

const AddEventManager = () => {

    const [addNewEventManager, { isLoading }] = useAddNewEventManagerMutation()
    const [eventManager, setEventManager] = useState({ ename: '', enumber: '', epassword: '' })
    const refClose = useRef('');

    const changeHandle = (e) => {
        const { name, value } = e.target
        setEventManager({ ...eventManager, [name]: value })
    }


    const handleClick = async (e) => {

        e.preventDefault();

        try {
            await addNewEventManager({ name: eventManager.ename, number: eventManager.enumber, password: eventManager.epassword }).unwrap()
            setEventManager({ ename: '', enumber: '', epassword: '' })
            swal("Success", "Data Added Successfully")
            refClose.current.click()
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <button type="button" className="bg-left-navabr  text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas" aria-controls="depOffCanvas"><i className="fa fa-plus" aria-hidden="true"></i> Add New Manager</button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffCanvas" aria-labelledby="depOffCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Manager</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Name
                                </label>
                                <input value={eventManager.ename} onChange={changeHandle} className="form-control" type='text' name='ename' />

                            </div>

                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Number
                                </label>
                                <input value={eventManager.enumber} onChange={changeHandle} className="form-control" type='number' name='enumber' />

                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Password
                                </label>
                                <input value={eventManager.epassword} onChange={changeHandle} className="form-control" type='password' name='epassword' />

                            </div>


                            <div className="col-md-12">
                                <button type="submit" className="bg-left-navabr  text-light  btn btn-secondary w-100 mt-3 rounded-pill"
                                    onClick={handleClick}
                                // disabled={!(true)}
                                >
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEventManager;