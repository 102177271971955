import { createEntityAdapter } from "@reduxjs/toolkit"; 
import { apiSlice } from "../../api/apiSlice";

export const eventManagerAdaptor = createEntityAdapter({
    selectId: (entity) => entity.id,
})

const initialEventManager = eventManagerAdaptor.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEventManager: builder.query({
            query: (page) => `/b2g/eco-admin/event-manager`,
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return eventManagerAdaptor.setAll(initialEventManager, responseData?.data || [])
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (result?.ids) {
                    return [
                        { type: 'EventManager', id: 'List' },
                        ...result.ids.map((EventManager) => ({ type: 'EventManager', id: EventManager.id })),
                    ];
                }
            }
        }),
        addNewEventManager: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/event-manager',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'EventManager', id: 'List' }
            ]
        }),
        editEventManager: builder.mutation({
            query: payLoad => ({
                url: `/b2g/eco-admin/event-manager`,
                method: 'PATCH',
                body: payLoad.body,
            }),
            invalidatesTags: [
                { type: 'EventManager', id: 'List' }
            ]
        }),
        deleteEventManager: builder.mutation({
            query: id => ({
                url: `/b2g/eco-admin/event-manager`,
                method: 'DELETE',
                body: id
            }),
            invalidatesTags: [
                { type: 'EventManager', id: 'List' }
            ]
        }),
    })
})

export const {
    useGetEventManagerQuery,
    useAddNewEventManagerMutation,
    useEditEventManagerMutation,
    useDeleteEventManagerMutation

} = extendedApiSlice

