import { useNavigate } from "react-router-dom";
import { useDeleteEventMutation, useEditEventMutation, useGetEventQuery } from "./EventSlice";
import swal from "sweetalert";

const EventExcerpt = ({ EventId, index, editEvent }) => {
    const navigate = useNavigate();
    const [deleteEvent] = useDeleteEventMutation();
    const [editEventActive] = useEditEventMutation()

    const { Event } = useGetEventQuery('getEvent', {
        selectFromResult: ({ data }) => ({
            Event: data?.entities[EventId],
        }),
    });

    const deleteHandle = async () => {
        try {
            await deleteEvent({ event_id: EventId }).unwrap();
            swal("Done", "Data Deleted Successfully", "success")

        } catch (error) {
            console.error('Failed to delete the dailyWaste: ', error);
            swal("Error", "Failed to delete the dailyWaste", "Error")
        }
    };


    const handleClick = async (e) => {
        e.preventDefault();
        try {
            await editEventActive({ body: { event_id: Event.id, is_active: Event.is_active === 1 ? '0' : "1" } }).unwrap();
            swal('Success', "Data Edited Successfully...")
        } catch (e) {
            console.error("Error in Daily Waste edit ->", e);
        }
    };

    const navigateClick = (propertyId) => {
        navigate('/b2g/Eventmanager', { state: { propertyId } });
    };
    return (
        <>
            <tr key={index} className="search_button listData search_button text-left fw-normal" >
                <td onClick={() => navigateClick(Event.id)}>{index - 1}</td>
                <td onClick={() => navigateClick(Event.id)}>{Event?.name}</td>
                <td onClick={() => navigateClick(Event.id)}>
                    <div className="form-check form-switch">
                        <input className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={Event.is_active === 1}
                            onChange={handleClick}
                            id="flexSwitchCheckChecked"
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                    </div>
                </td>
                <td>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle p-2"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ border: 'none' }}
                        >
                            <i className="fas fa-ellipsis-v" style={{ color: 'gray' }}></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div
                                    className="dropdown-item"
                                    onClick={() => { editEvent(Event) }}
                                >
                                    Edit
                                </div>

                            </li>
                            <li>
                                <div
                                    className="dropdown-item"
                                    onClick={deleteHandle}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Delete
                                </div>

                            </li>
                        </ul>
                    </div>
                </td>
            </tr >
            <div className="Card_table_data">
                <div onClick={() => navigateClick(Event.id)}>
                    <div div className="Card_data">
                        <span className="Card_srNo">{index - 1}</span>
                    </div>
                    <div className="Card">
                        <div className="Card_data">
                            <span className="Card_srNo">Name :-</span>
                            <span className="Card_data_list">{Event?.name}</span>
                        </div>
                        <div className="Card_data">
                            <span className="Card_srNo">Active :-</span>
                            <span className="Card_data_list">  <div className="form-check form-switch">
                                <input className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={Event.is_active === 1}
                                    onChange={handleClick}
                                    id="flexSwitchCheckChecked"
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                            </div></span>
                        </div>
                    </div>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Action :-</span>
                        <span className="Card_data_list"> <td>
                            <div className="dropdown">
                                <button
                                    className="btn dropdown-toggle p-2"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{ border: 'none' }}
                                >
                                    <i className="fas fa-ellipsis-v" style={{ color: 'gray' }}></i>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <div
                                            className="dropdown-item"
                                            onClick={() => { editEvent(Event) }}
                                        >
                                            Edit
                                        </div>

                                    </li>
                                    <li>
                                        <div
                                            className="dropdown-item"
                                            onClick={deleteHandle}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Delete
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </td>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventExcerpt;
