import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Avatar from "react-avatar";
import useLoadingPage from "../../../../hooks/useLoadingPage";
import SpinnerForPage from "../../../../components/spinner/SpinnerForPage";
import SpinnerForTable from "../../../../components/spinner/SpinnerForTable";
import MainBreadcrumb from "../../../../components/breadcrumb/MainBreadcrumb";

const CurrentPickups = ({ urlMain, token, currentPickupsData, setCurrentPickupsData, currentPickupsSearch, setCurrentPickupsSearch }) => {

    const loadingPage = useLoadingPage();
    const [loadingTable, setLoadingTable] = useState(true);
    const navigate = useNavigate();

    const recordsPerPage = 10;
    const lastBooked = currentPickupsSearch.page * recordsPerPage;
    const firstBooked = lastBooked - recordsPerPage;
    const records = currentPickupsData?.data?.slice(firstBooked, lastBooked);
    const npage = Math.ceil(currentPickupsData?.data?.length / recordsPerPage);

    const getSchedulePickupApi = async () => {
        try {
            setLoadingTable(true);
            const response = await fetch(`${urlMain}/pickup/current?status=${currentPickupsSearch.status}&propertyName=${currentPickupsSearch.propertyName}&startDate=${currentPickupsSearch.startDate}&endDate=${currentPickupsSearch.endDate}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            })
            const data = await response.json();
            if (data.status === true) {
                setCurrentPickupsData(data.data);
                setLoadingTable(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const changeSearch = (e) => setCurrentPickupsSearch({ ...currentPickupsSearch, [e.target.name]: e.target.value });

    const handlePageChange = (newPage) => {
        setCurrentPickupsSearch((prevSearch) => ({
            ...prevSearch,
            page: newPage,
        }));
    };

    const handleReset = () => window.location.reload();

    useEffect(() => {
        getSchedulePickupApi();
        document.title = "Current Pickups - Admin Panel | CRM";
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loadingPage ? <SpinnerForPage /> :
                    <div className="container-fluid mt-5">
                        {/* <div className="row">
                            <div className="col-md-12">
                                <h3 className="page-title">Current Pickups</h3>
                                <ul className="breadcrumb">
                                    <MainBreadcrumb />
                                    <li className="breadcrumb-item">
                                        <span className="breadcrumb-active">Current Pickups</span>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="row mt-4">
                            {
                                currentPickupsData?.card?.map((element, index) => {
                                    return (
                                        <div className="col-md-3" key={index}>
                                            <div className="card">
                                                <div className="card-body text-center status-info">
                                                    <h6>{element.cardData}</h6>
                                                    <h4>{element.TotalPickups}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="d-flex">
                            <div className="col-md-2 mt-2">
                                <div className="form-floating">
                                    <select className="form-select" id="status" name="status" value={currentPickupsSearch.status} onChange={changeSearch}>
                                        <option value="">---Select---</option>
                                        <option value="0">Pending</option>
                                        <option value="2">Booked</option>
                                        <option value="3">Started</option>
                                        <option value="4">Reached</option>
                                    </select>
                                    <label htmlFor="status" className="mb-3">Status</label>
                                </div>
                            </div>
                            <div className="col-md-2 mt-2">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="propertyName" name="propertyName" placeholder="Search" autoComplete="off" value={currentPickupsSearch.propertyName} onChange={changeSearch} />
                                    <label htmlFor="propertyName" className="mb-3">Property Name</label>
                                </div>
                            </div>
                            <div className="col-md-2 mt-2">
                                <div className="form-floating">
                                    <input type="date" className="form-control" id="startDate" name="startDate" placeholder="Search" autoComplete="off" max={moment(new Date()).format("YYYY-MM-DD")} value={currentPickupsSearch.startDate} onChange={changeSearch} />
                                    <label htmlFor="startDate" className="mb-3">Start Date</label>
                                </div>
                            </div>
                            <div className="col-md-2 mt-2">
                                <div className="form-floating">
                                    <input type="date" className="form-control" id="endDate" name="endDate" placeholder="Search" autoComplete="off" min={currentPickupsSearch.startDate} max={moment(new Date()).format("YYYY-MM-DD")} value={currentPickupsSearch.endDate} onChange={changeSearch} />
                                    <label htmlFor="endDate" className="mb-3">End Date</label>
                                </div>
                            </div>
                            <div className="d-flex gap-3 mt-2">
                                <div>
                                    <button type="button" className="btn btn-55ce63 btn-lg me-1" onClick={() => {
                                        handlePageChange(currentPickupsSearch.page = 1);
                                        getSchedulePickupApi();
                                    }}>Search</button>
                                </div>
                                <div>
                                    <button type="reset" className="btn btn-55ce63 btn-lg" onClick={handleReset}>Reset</button>
                                </div>
                            </div>
                        </div>
                        <div className="card card-dark mt-4">
                            {
                                loadingTable ? <SpinnerForTable /> : records?.length === 0 ? (
                                    <div className="text-center mt-5 mb-5 fw-bolder">No current pickup found at the momemt!</div>
                                ) : (
                                    <div className="card-block table-border-style">
                                        <div className="table-responsive">
                                            <table className=" search_button table table-striped">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th scope="col"></th>
                                                        <th scope="col">Property Name</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Driver Name</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Schedule Date</th>
                                                        <th scope="col">Slot</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        records?.map((element, index) => {
                                                            return (
                                                                <tr className="text-left fw-normal cp" key={index} onClick={() => {
                                                                    navigate("/main:currentPickupsDetails", { state: element })
                                                                }}>
                                                                    <td><Avatar round={true} size="35" name={element.PropertyName} /></td>
                                                                    <td className="fw-bold">{element.PropertyName}<div className="fw-lighter text-muted">{element.Address.split(",")[element.Address.split(",").length - 4]}</div></td>
                                                                    <td>{element.Name === "" ? element.ContactPerson : element.Name === null ? element.ContactPerson : element.Name}<div className="fw-lighter text-muted">{element.Number === "" ? element.ContactNumber : element.Number === "" ? element.ContactNumber : element.Number}</div></td>
                                                                    <td>{element.DriverName === "\"\"" ? "Not Assigned" : element.DriverName}<div className="fw-lighter text-muted">{element.DriverNumber === "\"\"" ? "Not Assigned" : element.DriverNumber}</div></td>
                                                                    <td>{element.PickupStatus === "0" ? <span className="badge bg-warning">Pending Pickup</span> : element.PickupStatus === "2" ? <span className="badge bg-primary">Booked Pickup</span> : element.PickupStatus === "3" ? <span className="badge bg-secondary">Started Pickup</span> : element.PickupStatus === "4" ? <span className="badge bg-info">Reached Pickup</span> : ""}</td>
                                                                    <td>{moment(element.ScheduleTime).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</td>
                                                                    <td>{element.FutureSlot === "" ? <span className="badge bg-primary">On Date</span> : <span className="badge bg-info">{element.FutureSlot}</span>}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>

                                            <div>
                                                {
                                                    records?.map((element, index) => {
                                                        return (
                                                            <div key={index} onClick={() => {
                                                                navigate("/main:currentPickupsDetails", { state: element })
                                                            }} className="box_admin_data Card_table_data">
                                                                <div div className="Card_data">
                                                                    <span className="Card_srNo"><Avatar round={true} size="35" name={element.PropertyName} /></span>
                                                                </div>
                                                                <div className="Card">
                                                                    <div className="Card_data">
                                                                        <span className="Card_srNo">Property Name :-</span>
                                                                        <span className="Card_data_list">{element.PropertyName}<div className="fw-lighter text-muted">{element.Address.split(",")[element.Address.split(",").length - 4]}</div></span>
                                                                    </div>
                                                                    <div className="Card_data">
                                                                        <span className="Card_srNo">Name :-</span>
                                                                        <span className="Card_data_list">{element.Name === "" ? element.ContactPerson : element.Name === null ? element.ContactPerson : element.Name}<div className="fw-lighter text-muted">{element.Number === "" ? element.ContactNumber : element.Number === "" ? element.ContactNumber : element.Number}</div></span>
                                                                    </div>
                                                                </div>
                                                                <div className="Card">
                                                                    <div className="Card_data">
                                                                        <span className="Card_srNo">Driver Name :-</span>
                                                                        <span className="Card_data_list">{element.Name === "" ? element.ContactPerson : element.Name === null ? element.ContactPerson : element.Name}<div className="fw-lighter text-muted">{element.Number === "" ? element.ContactNumber : element.Number === "" ? element.ContactNumber : element.Number}</div></span>
                                                                    </div>
                                                                    <div className="Card_data">
                                                                        <span className="Card_srNo">Status :-</span>
                                                                        <span className="Card_data_list">{element.PickupStatus === "0" ? <span className="badge bg-warning">Pending Pickup</span> : element.PickupStatus === "2" ? <span className="badge bg-primary">Booked Pickup</span> : element.PickupStatus === "3" ? <span className="badge bg-secondary">Started Pickup</span> : element.PickupStatus === "4" ? <span className="badge bg-info">Reached Pickup</span> : ""}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="Card">
                                                                    <div className="Card_data">
                                                                        <span className="Card_srNo">Schedule Date :-</span>
                                                                        <span className="Card_data_list">{moment(element.ScheduleTime).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</span>
                                                                    </div>
                                                                    <div className="Card_data">
                                                                        <span className="Card_srNo">Slot :-</span>
                                                                        <span className="Card_data_list">{element.FutureSlot === "" ? <span className="badge bg-primary">On Date</span> : <span className="badge bg-info">{element.FutureSlot}</span>}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="container mt-3 mb-3" id="paginationnumbers">
                                            <div className="pagination" id="paginationnumbers">
                                                <div className="page-item text-left">
                                                    <button className="page-link" disabled={currentPickupsSearch.page === 1} onClick={() => handlePageChange(currentPickupsSearch.page = 1)}>
                                                        1
                                                    </button>
                                                </div>
                                                <button className="page-link" disabled={currentPickupsSearch.page <= 1} onClick={() => handlePageChange(currentPickupsSearch.page - 1)}>
                                                    &laquo; Previous
                                                </button>
                                                <div className="page-item">
                                                    <div className="page-link">
                                                        {currentPickupsSearch.page} of {npage}
                                                    </div>
                                                </div>
                                                <div className="page-item text-left">
                                                    <button className="page-link" disabled={currentPickupsSearch.page === npage} onClick={() => handlePageChange(currentPickupsSearch.page + 1)}>
                                                        Next &raquo;
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )}
                        </div>
                    </div>
            }
        </>
    )
}

export default CurrentPickups;