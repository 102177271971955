import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

export const eventDustbinAdaptor = createEntityAdapter({
    selectId: (entity) => entity.id,
})

const initialEventDustbin = eventDustbinAdaptor.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEventDustbin: builder.query({
            query: (page) => `/b2g/eco-admin/dustbin-type`,
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return eventDustbinAdaptor.setAll(initialEventDustbin, responseData?.data || [])
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (result?.ids) {
                    return [
                        { type: 'EventDustbin', id: 'List' },
                        ...result.ids.map((EventDustbin) => ({ type: 'EventDustbin', id: EventDustbin.id })),
                    ];
                }
            }
        }),
        addNewEventDustbin: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/dustbin-type',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'EventDustbin', id: 'List' }
            ]
        }),
        editEventDustbin: builder.mutation({
            query: payLoad => ({
                url: `/b2g/eco-admin/dustbin-type`,
                method: 'PATCH',
                body: payLoad.body,
            }),
            invalidatesTags: [
                { type: 'EventDustbin', id: 'List' }
            ]
        }),
        deleteEventDustbin: builder.mutation({
            query: id => ({
                url: `/b2g/eco-admin/dustbin-type`,
                method: 'DELETE',
                body: id
            }),
            invalidatesTags: [
                { type: 'EventDustbin', id: 'List' }
            ]
        }),

    })
})

export const {
    useGetEventDustbinQuery,
    useAddNewEventDustbinMutation,
    useEditEventDustbinMutation,
    useDeleteEventDustbinMutation
} = extendedApiSlice

