import React, { useState } from 'react';
import IecExcerpt from './iecExcerpt';
import SpinnerForPage from '../../components/spinner/SpinnerForPage';
import AddIECPersonOffCanavas from './addIECPerson';
import { useGetIECPersonQuery } from './iecPersonSlice';

const CollectionIEC_Person = () => {
    const {
        data: iec,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetIECPersonQuery('getIECPerson')


    const [filterSearch, setFilterSearch] = useState({})
    const [filterien, setFilterien] = useState()

    const searchHandle = (e) => {
        setFilterSearch({ ...filterSearch, [e.target.name]: e.target.value })
    }
    const filterData = () => {
        const filteredData = iec?.ids?.filter((inc_id) => {
            const districtEntity = iec?.entities[inc_id];
            return (
                (!filterSearch?.inc_number || districtEntity?.inc_number?.toLowerCase().includes(filterSearch?.inc_number.toLowerCase()))
                &&
                (!filterSearch?.inc_name || districtEntity?.inc_name?.toLowerCase().includes(filterSearch?.inc_name?.toLowerCase()))

            );
        });

        setFilterien(filteredData);
    };
    return (
        <div>
            {isLoading && <SpinnerForPage />}

            <div className="container-fluid mt-5">
                <div className="d-flex justify-content-end">


                </div>
                <div className="Card_table_data w-25">
                    <button type="button" className="Add_button bg-left-navabr w-100 text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffsearch" aria-controls="depOffsearch"> Search</button>

                    <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffsearch" aria-labelledby="depOffsearch">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvas1Label">Search</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                        </div>
                        <input className="form-select mt-2" onChange={searchHandle} name="inc_name" type="text" placeholder="Search  Name" />
                        <input className="form-select mt-2" onChange={searchHandle} name="inc_number" type="text" placeholder="Search  Number" />
                        <button className="bg-left-navabr mt-2 text-light  btn btn-secondary" onClick={filterData} data-bs-dismiss="offcanvas" aria-label="Close" >Data Search</button>
                    </div>
                </div>
                <div className="search_button w-100 d-flex justify-content-center gap-5">

                    <input className="form-select w-25" onChange={searchHandle} name="inc_name" type="text" placeholder="Search  Name" />
                    <input className="form-select w-25" onChange={searchHandle} name="inc_number" type="text" placeholder="Search  Number" />
                    <button className="btn btn-secondary bg-left-navabr" onClick={filterData}>dataSearch</button>

                    <div className="text-right">
                        <AddIECPersonOffCanavas />
                    </div>
                </div>

                <div className="card card-dark mt-4 sidebarhome">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className='search_button'>
                                    <tr className="text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col"> Number</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        filterien?.map((incID, index) => {
                                            return <IecExcerpt key={index} incID={incID} index={index} />
                                        })
                                    }

                                    {filterien == null &&
                                        iec?.ids?.map((incID, index) => {
                                            return <IecExcerpt key={index} incID={incID} index={index} />
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollectionIEC_Person;
